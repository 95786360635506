.products_wrapper {
    padding: 2.5em !important;
    }
    .add_to_cart_container {
      position: -webkit-sticky !important; /* Safari */
      position: sticky !important;
      top: 0 !important;
      text-align: center;
    }
    
    .products_image {
        display: flex !important;
        justify-content: center !important;
        text-align: center;
        max-height:300px;
        object-fit: cover !important;
        object-position: center !important;
    
    }
    .product_row{
    
    }
    .vendor_row {
        
    }
    .rainbow-google-map .rainbow-google-map_coordinates-container {
        display:none !important;
    }
    .report_label {
        background-color: transparent !important;
        border-color: transparent !important;
        color: #DB2828 !important;
        margin-top: 0.3em !important;
    }
    .time_remaining_label {
        text-align:center !important;
        font-size: 1em !important;
        font-weight: 600;
        z-index: 1 !important;
    }
    .sc-bxivhb ul {
        list-style: none !important;
        padding-inline-start: 0 !important;
    }
    .gm-style-iw.gm-style-iw-c {
        padding: 15px !important;
    }
    .rainbow_map .sc-bxivhb {
        display: none;
    }