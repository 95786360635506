.hover_menu {
    position: fixed;
    right: 1rem;
    top: 2rem;
    z-index: 1000002;
    padding: 1rem;
    box-shadow: rgba(34, 36, 38, 0.25) 0px 2px 2px 0px;
}

/* .hover_menu .item > i.icon {
    width: 1.18em !important;
    float: left !important;
    margin: 0em 1em 0em 0em !;
} */