/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
  
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  /* Buttons and links */
.stripebutton {
    background: #0a721b;
    border-radius: var(--radius);
    color: white;
    border: 0;
    padding: 12px 16px;
    margin-top: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}
  .stripebutton:hover {
    filter: contrast(115%);
  }
  .stripebutton:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
  }
  .stripebutton:disabled {
    opacity: 0.5;
    cursor: none;
  }

  a {
    color: var(--link-color);
    text-decoration: none;
    transition: all 0.2s ease;
  }
  
  a:hover {
    filter: brightness(0.8);
  }
  
  a:active {
    filter: brightness(0.5);
  }
