.buy_sell_modal_card  > img {
    max-height: 200px;
}

.ui.cards > .card > .image, .ui.card > .image {
    background: none !important;
}

.ui.cards > .card {
    margin: 2em 2em;
    }