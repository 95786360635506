.ui.segment.active.tab {
      padding-left:5%;
      padding-right:5%;
      margin-top:3%;
    }

.ui.segment {
      margin-top: 0px !important;
    }

.ui.menu a.active {
        background-color: #fff !important;
        color: #129490 !important;
        font-weight: 600 !important;
        border-style: solid;
        border-bottom-width: 3px;
        border-left-width: 0px;
        border-right-width: 0px;
        border-top-width: 0px;
      }
.ui.menu a.item {
        color: #000;
        padding-left: 2em;
        padding-right: 2em;
    }
.ui.menu a.item::after {
          display: none !important;
        }
.ui.menu a.item::before {
          display: none !important;
        }
.ui.menu a.item:hover {
          background-color: #fff;
          color: #5c5c5c !important;
        }
.ui.menu a.item>i.icon {
          margin: 0 1em 0 0 !important;
        }
      
.dashboard_tab {
  margin-top: 4em !important;
}
.dashboard_tab .ui.menu {
  margin-bottom: 0px !important;
      font-size:1.1rem !important;
}

.ui.secondary.pointing.menu .item {

  padding: 1em 1.14285714em !important;
}

.embed-container { 
  position: relative; 
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
} 

.embed-container iframe,
.embed-container object,
.embed-container embed { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}