.short_input {
    max-width: 200px;
}
.input_dropdown_label {
    max-height: 40px;
}
.center_crop_card {
    object-fit: cover;
    object-position: center;
    max-height: 300px;
}

.item_icon {
    color: #ef9564 !important;
    padding-top: 3rem;
    margin-right: 1rem !important;
}
.stats_wrapper {
    margin-top: 3em !important;
    min-height: 10rem !important;
}
.header_wrapper {
    margin-bottom: 0.5em !important;
}

.empty_state_image {
    display: flex !important;
}

.no_results {
    flex-direction: column !important;
}
.no_results_card {
    width: 100% !important;
}