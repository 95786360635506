.main_top_bar {
    background-image: linear-gradient(135deg, #e10915, #000) !important;
    height: 4em !important;
    box-shadow: 0px 0px 6px 3px rgba(34, 36, 38, 0.35) !important;

}
.main_top_bar_admin {
    background-image: linear-gradient(135deg, #000, #000) !important;
    height: 4em !important;
    box-shadow: 0px 0px 6px 3px rgba(34, 36, 38, 0.35) !important;

}
.main_top_bar_vendor {
    background-image: linear-gradient(135deg,#271F8A,#5E7BED) !important;
    height: 4em !important;
    box-shadow: 0px 0px 6px 3px rgba(34, 36, 38, 0.35) !important;

}
.logo_image {
    max-height: 3em !important;
    margin-left: 1em !important;
}
.logo_image_container {
    color: rgb(255, 255, 255) !important;
    font-weight: bold !important;
    position: inherit !important;
}
.logo_image_link {

    vertical-align: middle !important;
    display: flex !important;
}

.avatar_image {

    box-shadow: 1px 1px 2px 1px rgba(34, 36, 38, 0.35) !important;
}

.avatar_image:hover {

    box-shadow: 1px 1px 4px 2px rgba(34, 36, 38, 0.35) !important;
}