.home_wrapper {
  margin:0px !important;
  height:100vh;
 
}
.map_results_wrapper {
padding-bottom: 0px !important;
padding-left: 0px !important;
padding-right: 0px !important;
padding-top: 0px !important;
}


.map_wrapper {
padding-top: 3.5em !important;
padding-left: 2rem !important;
padding-right: 2rem !important;
height:100vh;
padding-bottom: 0px !important;
}

.results_wrapper {

overflow: auto;
  /*height:100vh;*/
  padding-left: 3rem !important;
  padding-right: 3rem !important;
padding-top: 5em !important;
overflow-x: hidden;

}
.map_results {
margin-top: 0em !important;
}

.filters_wrapper {
height: 100%;
overflow: auto;
padding-top: 4em !important;
padding-left:2rem !important;
padding-right:1rem !important;
z-index: 10;
border-right: 1px solid #f0f0f0;
background: #FFF;
}
.filters_wrapper label {
  margin-left: 5px;
}
.filters_wrapper > div {
  margin: 20px 0; /* 40px */
}

.center_crop_small_card {
  object-fit: cover;
  object-position: center;
  max-height: 130px;
}
.second_image_label {
  top:100px !important;
}
.card_small_header {
  font-weight: 500 !important;
  font-size: 1em !important;
  margin-top: -0.21425em !important;
  line-height: 1em !important;
}
.product_card {
width: calc( 33.33333333% - 1em ) !important;
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
  margin-top:1em !important;
  margin-bottom:1em !important;
}
.product_card .content 
{
padding: 0.7em !important;
}
.product_card .content .meta
{
font-size: 0.7em !important;
}
.product_card .content .description {
text-align: center !important;
justify-content: space-around;
  display: flex;
}
.product_card .extra.content {
font-size: 0.8em !important;
}
.no_results_image {
display: flex !important;
}
.product_modal {
z-index: 1000001 !important;
}
.ui.page.modals {
z-index:1000003 !important;
}
::-webkit-scrollbar {
display: none;
}