body {
  margin: 0;
  padding: 0;
  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #19263D;
}

.ui.menu .item {
  color: #19263D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ui.segment.active.tab {
  padding: 3rem 8rem !important;
  }
.pac-container {
  z-index: 1000004 !important;
}